$vic-black: #100e17;
$vic-gray: #666666;
$vic-orange: #fc8016;

$vic-theme: (
  --theme-primary: $vic-black,
  --theme-secondary: $vic-gray,
  --theme-accent: $vic-orange,
  /* Menu list */ --menu-list-text-color: white,
  --menu-list-active-text-color: $vic-orange,
  --menu-list-hover-background-color: $vic-gray,
  --menu-list-hover-text-color: white,
);
